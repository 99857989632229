/* eslint-disable */
import axios from 'axios';
import * as dotenv from 'dotenv';
dotenv.config();
import { store } from "../store/store";

// Initializing variables
const apiUrl = process.env.API_URL

/**
 * This is the function that takes in the comment and the source, and returns a key and a question that can 
 * be then used to build a playbook play-load object to add a new playbook. 
 * @param comment: str, the comment that the user added on the current open document.
 * @param source: str, the source, on which the comment is added.
 */
async function keyQuestionGenerator(comment: string, source: string) {
    try {
        const storedUserId = localStorage.getItem("activeUserId");
        // const userId = store.getState().user.userDetails.userId; // Access userId from the store
        // Preparing the play-load to send to backend, which will return a key-question pair.
        const payload = {
            userId: storedUserId,
            standardAnswer: comment,
            wrongAnswer: source
        }
        
        // Making the playbook analysis API request
        const response =  await axios.post(apiUrl+'/api/v1/add-in/key-gen', payload,
            {
                headers: { 
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
            }
        );
        const responseData =  response.data.data;
        
        return responseData;
    } catch (error) {
        debugger;
        throw error;
    }
}

/**
 * This is the function that is being used to add the actual playbook in user's account.
 * @param payload: object, this is the pay-load that will be sent to the backend through add-key-terms API.
 */
async function addPlaybook(payload: object) {
    try {
        // Making the playbook analysis API request
        const response =  await axios.post(apiUrl+'/api/v1/add-master-key-term', payload,
            {
                headers: { 
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
            }
        );
        const responseData =  response.data;
        return responseData;
    } catch (error) {
        throw error;
    }
}

/**
 * This function is used to update the metadata of the generated playbook.
 * @param payload: object, this is the pay-load that will be sent to the backend through add-key-terms API.
 */
async function updatePlaybookMetadata(payload: object) {
    try {
        // Making the playbook analysis API request 
        const response =  await axios.post(apiUrl+'/api/v1/add-in/update-playbook', payload,
            {
                headers: { 
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
            }
        );
        const responseData =  response.data;
        return responseData;
    } catch (error) {
        throw error;
    }
}

/**
 * Internal function to get all the playbooks generated by the user.
 * @param userId: Unique ID of the user.
 * @param playbookId: Pass in a playbook id to get all the key terms for that particular playbook.
 */

async function getUserPlaybooks(userId:string, playbookId: string = undefined) {
    try {
        // Making the request to the backend
        const response =  await axios.get(
            apiUrl+'/api/v1/add-in/get-user-playbooks',
            {
                params: {
                    userId: userId,
                    playbookId: playbookId
                },
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                } 
            }
        );
        const responseData =  response.data.userPlaybooks;
        return responseData;
    } catch (error) {
        throw error;
    }
}

async function fetchPlaybookTerms(UserId:string,PlaybookId:string){
    // console.log("userid received in backend fetchplaybook fn:",UserId)
    // console.log("playbookis received in backend fetchplaybook fn:",PlaybookId)
    try{
        const getUserPlaybooksResponse = await axios.get(apiUrl + "/api/v1/add-in/get-user-playbooks", {
            params: {
              userId: UserId,
              playbookId: PlaybookId,
            },
            headers:{
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`
            }
          });
    
          const userPlaybooks = getUserPlaybooksResponse.data.playbookKeys;
        //   console.log("playbookdetails in helper fn backend:",userPlaybooks);
        return(userPlaybooks);
    }catch(error){

    }
}



async function switchPlaybook (userId: string, playbookId: any) {
    // console.log("playboookid recived in switch",playbookId);
    try {
        // Building the payload
        const payload = {
            userId: userId,
            toActiveId: playbookId
        }

        // Making the request to the backend
        const response =  await axios.post(apiUrl+'/api/v1/add-in/toggle-active-playbook', payload,
            {
                headers: { 
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                },
            }
        );

        const responseData =  response;
        
        return responseData.data.success;
    } catch (error) {
        throw error;
    }
}

/**
 * This function generates a playbook using the current open document.
 * @param userId: Unique ID of the user.
 * @param fileId: Unique ID for the file which is returned by the upload function.
 */
async function generatePlaybook(userId:string, fileId: string) {
    try {
        // Building the payload
        const formData = new FormData();
        formData.append('userId', userId);
        formData.append('file_id', fileId);
        formData.append('autoGenerate', 'true');
        
        
        // Making the playbook generation request to the backend
        const response = await axios.post(`${apiUrl}/api/v1/playbook/generate-playbook`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem("authToken")}`
            },
        });
        const responseData =  response.data;

        if (responseData.success){
         console.log("Successfully generated playbook")
        }
        return responseData;
    } catch (error) {
        throw error;
    }
}
/**
 * Analyze contract using the latest generated V1 playbook.
 * @param userId: Unique ID of the user.
 * @param fileId: Unique ID for the file which is returned by the upload function.
 */
async function analyzeByLatestPlaybook(userId: string, fileId: string) {
    try {
        // Getting playbooks generated by the user.
        const responseData = await getUserPlaybooks(userId);

        // Getting the latest saved playbook by comparing creation time stamps.
        const latestPlaybook = responseData.reduce((latest, current) => {
            const latestTime = new Date(latest.creationDataTime);
            const currentTime = new Date(current.creationDataTime);
            return currentTime > latestTime ? current : latest;
        });
        
        debugger;
        return true
        
    } catch (error) {
        throw error;
    }
}


export { keyQuestionGenerator, addPlaybook, updatePlaybookMetadata, generatePlaybook, getUserPlaybooks,fetchPlaybookTerms, switchPlaybook };